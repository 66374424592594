<template>
  <div class="bodyBg-image">
    <img src="@/assets/img/img3.png" class="img3">
    <my-header></my-header>
    <div class="pr pt40">
      <div class="w1200 box plr25">
        <mytitle title="胃健康中医评估"></mytitle>

        <el-table :data="tableData" border :header-cell-style="{background:'#F6F5FE'}" :header-cell-class-name='hdRed' style="width: 100%; margin-top: 20px">
          <el-table-column label="" align="center">
            <template slot-scope="{row}">
              <div>{{row.name1}}</div>
            </template>
          </el-table-column>
          <el-table-column label="肝胃气滞证" align="center">
            <template slot-scope="scope">
              <div class="c999" :class="scope.row.isRed2.call(scope._self,scope.$index,scope.row)" v-if="scope.row.name2">{{scope.row.name2}}</div>
              <div v-if="scope.row.img2">
                <img :src="scope.row.img2.call(scope._self,scope.$index,scope.row)" alt="">
              </div>
              <div v-if="scope.row.she2" v-html="scope.row.she2.call(scope._self,scope.$index,scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column label="肝胃郁热证" align="center">
            <template slot-scope="scope">
              <div class="c999" :class="scope.row.isRed3.call(scope._self,scope.$index,scope.row)" v-if="scope.row.name3">{{scope.row.name3}}</div>
              <div v-if="scope.row.img3">
                <img :src="scope.row.img3.call(scope._self,scope.$index,scope.row)" alt="">
              </div>
              <div v-if="scope.row.she3" v-html="scope.row.she3.call(scope._self,scope.$index,scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column label="脾胃湿热证" align="center">
            <template slot-scope="scope">
              <div class="c999" :class="scope.row.isRed4.call(scope._self,scope.$index,scope.row)" v-if="scope.row.name4">{{scope.row.name4}}</div>
              <div v-if="scope.row.img4">
                <img :src="scope.row.img4.call(scope._self,scope.$index,scope.row)" alt="">
              </div>
              <div v-if="scope.row.she4" v-html="scope.row.she4.call(scope._self,scope.$index,scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column label="脾胃气虚证" align="center">
            <template slot-scope="scope">
              <div class="c999" :class="scope.row.isRed5.call(scope._self,scope.$index,scope.row)" v-if="scope.row.name5">{{scope.row.name5}}</div>
              <div v-if="scope.row.img5">
                <img :src="scope.row.img5.call(scope._self,scope.$index,scope.row)" alt="">
              </div>
              <div v-if="scope.row.she5" v-html="scope.row.she5.call(scope._self,scope.$index,scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column label="脾胃虚寒证" align="center">
            <template slot-scope="scope">
              <div class="c999" :class="scope.row.isRed6.call(scope._self,scope.$index,scope.row)" v-if="scope.row.name6">{{scope.row.name6}}</div>
              <div v-if="scope.row.she6" v-html="scope.row.she6.call(scope._self,scope.$index,scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column label="胃阴不足证" align="center">
            <template slot-scope="scope">
              <div class="c999" :class="scope.row.isRed7.call(scope._self,scope.$index,scope.row)" v-if="scope.row.name7">{{scope.row.name7}}</div>
              <div v-if="scope.row.she7" v-html="scope.row.she7.call(scope._self,scope.$index,scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column label="胃络瘀阻证" align="center">
            <template slot-scope="scope">
              <div class="c999" :class="scope.row.isRed8.call(scope._self,scope.$index,scope.row)" v-if="scope.row.name8">{{scope.row.name8}}</div>
              <div v-if="scope.row.she8" v-html="scope.row.she8.call(scope._self,scope.$index,scope.row)"></div>
            </template>
          </el-table-column>
        </el-table>
        <div class="f14 c999 pt20">依据《慢性胃炎中医诊疗专家共识意见（2017）》，《慢性非萎缩性胃炎中西医结合诊疗共识意见（2017）》</div>
        <div class="f18 c333 mt30 bsnt" v-if="type != 0">若胃胀痛、大便干结、食欲不振的症状反复发作，建议您到医院就诊。</div>
      </div>
      <div class="w1200 box plr25 mt20">
        <div class="flex hd2 f18 c333">
          为您推荐胃部健康建议
        </div>
        <div class="f16 c666 pt20 btF">
          <div>1.提醒您注意避免以下饮食习惯：进餐无定时、进食过快、暴饮暴食、喜食热烫食、烧烤、口味偏咸等。</div>
          <div>2.平素饮食建议：饮食有节，宜“淡、衡、软、温、缓、细”，避免吸烟、酗酒、咖啡、浓茶等不良生活方式。</div>
          <div>3.个性化饮食建议： </div>
          <div>（1）脾胃虚寒或虚弱者，避免生冷不洁、糯米、油炸、干硬粗糙等难以消化之物；</div>
          <div>（2）脾胃湿热者，尤其要忌食辛辣醇酒、荤腥油腻之品； </div>
          <div>（3）胃脘痞胀、嗳气明显者宜少食豆类和奶类制品；</div>
          <div>（4）慢性胃炎患者应尽量避免服用对胃黏膜有刺激或损伤的食物（如辛辣食物、含亚硝酸盐食物等）。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getItem } from '@/assets/js/storage'
export default {
  name:'',
  data(){
    var that = this;
    return {
      type: 0,
      check: require('@/assets/img/check.png'),
      checkout: require('@/assets/img/checkout.png'),
      obj: {
        "wt": "",
        "wtcd": "",
        "wzcd": "",
        "zzfour": "",
        "aqcd": "",
        "fscd": "",
        "kgcd": "",
        "kkcd": "",
        "zznine": "",
        "zzten": "",
        "shexiang": {
          "yuban": 0,
          "huang0_bai1": 0,
          "hong0_danhong1_danbai2_qingzi3": 0,
          "chihen": 0,
          "bo0_hou1": 0,
          "dianci": 0,
          "ni": 0,
          "liewen": 0,
          "botuo": 0
        },
        "url": ""
      },
      titleRed1: false,
      titleRed2: false,
      titleRed3: false,
      titleRed4: false,
      titleRed5: false,
      titleRed6: false,
      titleRed7: false,
      tableData: [
        {
          name1: '胃痛',
          name2: '胀痛',
          isRed2: () => {return that.obj.wt.includes('2') ? 'red' : ''},
          name3: '灼痛',
          isRed3: () => {return that.obj.wt.includes('4') ? 'red' : ''},
          name4: '胀痛',
          isRed4: () => {return that.obj.wt.includes('2') ? 'red' : ''},
          name5: '胀痛/隐痛',
          isRed5: () => {return that.obj.wt.includes('2') ? 'red' : ''},
          name6: '隐痛',
          isRed6: () => {return that.obj.wt.includes('1') ? 'red' : ''},
          name7: '灼痛',
          isRed7: () => {return that.obj.wt.includes('4') ? 'red' : ''},
          name8: '胀痛/刺痛',
          isRed8: () => {return that.obj.wt.includes('3') ? 'red' : ''},
        },
        {
          name1: '嗳气',
          img2: () => {return that.obj.aqcd != '' ? that.check : that.checkout}
        },
        {
          name1: '反酸',
          img3: () => {return that.obj.fscd != '' ? that.check : that.checkout}
        },
        {
          name1: '口干',
          img3: () => {return that.obj.kgcd != '' ? that.check : that.checkout}
        },
        {
          name1: '口苦',
          img3: () => {return that.obj.kkcd != '' ? that.check : that.checkout}
        },
        {
          name1: '大便形态',
          name3: '干结',
          isRed3: () => {return that.obj.zznine.includes('1') ? 'red' : ''},
          name4: '粘滞/稀溏',
          isRed4: () => {return that.obj.zznine.includes('1') || that.obj.zznine.includes('3') ? 'red' : ''},
          name5: '稀溏',
          isRed5: () => {return that.obj.zznine.includes('2') ? 'red' : ''},
          name6: '稀溏',
          isRed6: () => {return that.obj.zznine.includes('2') ? 'red' : ''},
          name7: '干结',
          isRed7: () => {return that.obj.zznine.includes('1') ? 'red' : ''},
        },
        {
          name1: '畏寒',
          img4: () => {return that.obj.zzten.includes('1') ? that.check : that.checkout},
          img5: () => {return that.obj.zzten.includes('1') ? that.check : that.checkout}
        },
        {
          name1: '食欲不振',
          img3: () => {return that.obj.zzten.includes('2') ? that.check : that.checkout},
          img4: () => {return that.obj.zzten.includes('2') ? that.check : that.checkout},
          img5: () => {return that.obj.zzten.includes('2') ? that.check : that.checkout}
        },
        {
          name1: '精神乏力',
          img3: () => {return that.obj.zzten.includes('5') ? that.check : that.checkout},
          img4: () => {return that.obj.zzten.includes('5') ? that.check : that.checkout},
          img5: () => {return that.obj.zzten.includes('5') ? that.check : that.checkout}
        },
        {
          name1: '心烦易怒',
          img2: () => {return that.obj.zzten.includes('6') ? that.check : that.checkout}
        },
        {
          name1: '舌象',
          she2: () => {
            var red1 = that.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == 1 ? 'red' : '';
            var red2 = that.obj.shexiang.huang0_bai1 == 1 ? 'red' : '';
            var red3 = that.obj.shexiang.bo0_hou1 == 0 ? 'red' : '';
            var str = `<span class='${red1}'>舌质淡红</span>,<span class='${red2}'>苔白</span>,<span class='${red3}'>薄</span>`
            return str;
          },
          she3: () => {
            var red1 = that.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == 0 ? 'red' : '';
            var red2 = that.obj.shexiang.huang0_bai1 == 0 ? 'red' : '';
            var str = `<span class='${red1}'>舌质红</span>,<span class='${red2}'>苔黄</span>`
            return str;
          },
          she4: () => {
            var red1 = that.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == 0 ? 'red' : '';
            var red2 = that.obj.shexiang.huang0_bai1 == 0 ? 'red' : '';
            var red3 = that.obj.shexiang.ni == 1 ? 'red' : '';
            var str = `<span class='${red1}'>舌质红</span>,<span class='${red2}'>苔黄</span>,<span class='${red3}'>腻</span>`
            return str;
          },
          she5: () => {
            var red1 = that.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == 1 ? 'red' : '';
            var red4 = that.obj.shexiang.chihen == 1 ? 'red' : '';
            var red2 = that.obj.shexiang.huang0_bai1 == 1 ? 'red' : '';
            var red3 = that.obj.shexiang.bo0_hou1 == 0 ? 'red' : '';
            var str = `<span class='${red1}'>舌质淡红</span>,<span class='${red4}'>齿印</span>,<span class='${red2}'>苔白</span>,<span class='${red3}'>薄</span>`
            return str;
          },
          she6: () => {
            var red1 = that.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == 1 ? 'red' : '';
            var red4 = that.obj.shexiang.chihen == 1 ? 'red' : '';
            var red3 = that.obj.shexiang.bo0_hou1 == 0 ? 'red' : '';
            var str = `<span class='${red1}'>舌质淡红</span>,<span class='${red4}'>齿痕</span>,<span class='${red3}'>苔薄</span>`
            return str;
          },
          she7: () => {
            var red1 = that.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == 0 ? 'red' : '';
            var red2 = that.obj.shexiang.liewen == 1 ? 'red' : '';
            var str = `<span class='${red1}'>舌质红</span>,<span class='${red2}'>裂纹</span>`
            return str;
          },
          she8: () => {
            var red1 = that.obj.shexiang.yuban == 1 ? 'red' : '';
            var str = `<span class='${red1}'>瘀斑</span>`
            return str;
          },
        },
      ]
    }
  },
  mounted(){
    this.type = this.$route.query.type;
    this.obj = getItem('teach');
    console.log(this.tableData)
    // this.loadText();
    this.loadTitleRed();
  },
  methods:{
    loadTitleRed(){
      if(this.obj.wt.includes('2') || this.obj.aqcd != '' || this.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == '1' || this.obj.shexiang.huang0_bai1 == '1' || this.obj.shexiang.bo0_hou1 == '0'){
        this.titleRed1 = true;
      }
      if(this.obj.wt.includes('4') || this.obj.fscd != '' || this.obj.kgcd != '' || this.obj.kkcd != '' || this.obj.zznine.includes(1) || this.obj.zzten.includes(6) || this.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == '0' || this.obj.shexiang.huang0_bai1 == '0'){
        this.titleRed2 = true;
      }
      if(this.obj.wt.includes('2') || this.obj.zznine.includes(1) || this.obj.zznine.includes(3) || this.obj.zzten.includes(2) || this.obj.zzten.includes(5) || this.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == '0' || this.obj.shexiang.huang0_bai1 == '0' || this.obj.shexiang.ni == '1'){
        this.titleRed3 = true;
      }
      if(this.obj.wt.includes('1') || this.obj.wt.includes('2') ||  this.obj.zznine.includes(2) || this.obj.zzten.includes(1) || this.obj.zzten.includes(2) || this.obj.zzten.includes(5) || this.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == '1' || this.obj.shexiang.chihen == '1' || this.obj.shexiang.huang0_bai1 == '1' || this.obj.shexiang.bo0_hou1 == '0'){
        this.titleRed4 = true;
      }
      if(this.obj.wt.includes('1') ||  this.obj.zznine.includes(2) || this.obj.zzten.includes(1) || this.obj.zzten.includes(2) || this.obj.zzten.includes(5) || this.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == '1' || this.obj.shexiang.chihen == '1' || this.obj.shexiang.bo0_hou1 == '0'){
        this.titleRed5 = true;
      }
      if(this.obj.wt.includes('4') || this.obj.zznine.includes(1) || this.obj.shexiang.hong0_danhong1_danbai2_qingzi3 == '0' || this.obj.shexiang.liewen == '1'){
        this.titleRed6 = true;
      }
      if(this.obj.wt.includes('2') || this.obj.wt.includes('3') || this.obj.shexiang.yuban == '1'){
        this.titleRed7 = true;
      }
    },
    hdRed({row, column, rowIndex, columnIndex}){
      if(columnIndex != 0) {
        var index = 'titleRed' + columnIndex
        if(this[index]){
          return 'red'
        }
      }
      
    }
  }
}
</script>

<style scoped lang="scss">
  
  .pr{
    position: relative;
    z-index: 2;
    .box{
      background-color: #FFFFFF;
      border-radius: 20px;
      padding-bottom: 30px;
      .btF{
        line-height: 1.6;
      }
      .bsnt{
        height: 48px;
        line-height: 48px;
        background: #F7F5FF;
        border-radius: 8px;
        padding: 0 16px;
      }
      .hd2{
          width: 100%;
          height: 68px;
          border-bottom: 1px solid #EBEBEB;
          
        }
    }
  }
  
  .bodyBg-image{
    // height: 100vh;
    padding-bottom: 30px;
  }
  .img3{
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
</style>
